var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "router-layout-container" },
    [
      _c(
        "vue-page-transition",
        { attrs: { name: "fade-in-right" } },
        [_c("router-view", { staticClass: "router-layout" })],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }