<template>
  <div class="router-layout-container">
    <vue-page-transition name="fade-in-right">
      <router-view class="router-layout" />
    </vue-page-transition>
  </div>
</template>

<script>
export default {
  name: 'RouterLayout',
};
</script>

<style scoped lang="scss">
.router-layout-container {
  display: flex;
  justify-content: center;
  width: 100%;
}
</style>
